/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import MainLayout from '../Layouts/MainLayout';
import Home from '../pages/Home';
import Product from '../pages/Product/Product';
import Robots from '../pages/Robots/Robots';
import PiecesRechange from '../pages/PiecesRechange/PiecesRechange';
import Pannier from '../pages/Pannier/Pannier';
import ContactUs from '../pages/contact us/ContactUs';
import Register from '../pages/Authentification/Register';
import Login from '../pages/Authentification/Login';
import ProductPiece from '../pages/Product/ProductPiece';
import Index from '../pages/UserPages/Index';
import DataPersonnelles from '../pages/UserPages/DataPersonnelles/DataPersonnelles';
import Commandes from '../pages/UserPages/commandes/Commandes';
import Adresses from '../pages/UserPages/Adresses/Adresses';
import PrivateRoute from './PrivateRoute';
import AdminLayout from '../Layouts/AdminLayout';
import Gestion_commandes from '../pages/adminPages/Gestion des commandes/Gestion_commandes'
import LoginAdmin from '../pages/adminPages/LoginAdmin';
import AdminPrivateRoute from './AdminPrivateRoute';
import PaymentFailure from '../components/PaymentFailure';
import PaymentSuccess from '../components/PaymentSuccess';
import GetsionProduits from '../pages/adminPages/GestiondesProduits/GetsionProduits';
import Dashboard from '../pages/adminPages/Dashboard/Dashboard';
import GestionAdmins from '../pages/adminPages/Gestion admins/GestionAdmins';
import Blogs from '../pages/Blogs/Blogs';
import GuideUtilisation from '../pages/guideUtilisation/GuideUtilisation';
import Subscribers from '../pages/adminPages/Subscribers/Subscribers';
import ManageZone from '../pages/adminPages/zoneLivraison/ManageZone';
import Error from '../components/Error';
import ManageCoupon from '../pages/adminPages/Coupon/ManageCoupon';
import ConditionVent from '../pages/ConditionGeneraleVent/ConditionVent';
import PolitiqueConfidentialite from '../pages/ConditionGeneraleVent/PolitiqueConfidentialite';





export const router = createBrowserRouter([
    {
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                path: '/Product/:productSlug/:securite?',
                element: <Product />
            },
            {
                path: '/Politique-de-confidentialite',
                element: <PolitiqueConfidentialite />
            },
            {
                path: '/Product/Pieces-rechange/:productSlug',
                element: <ProductPiece />
            },
            {
                path: '/Robots',
                element: <Robots />
            },
            {
                path: '/Pieces-de-rechange',
                element: <PiecesRechange />
            },
            {
                path: '/panier',
                element: <Pannier />
            },
            {
                path: '/contact-us',
                element: <ContactUs />
            },
            {
                path: '/Blogs',
                element: <Blogs />
            },
            {
                path: '/guide-utilisation',
                element: <GuideUtilisation />
            },
            {
                path: '/Register',
                element: <Register />
            },
            {
                path: '/Login',
                element: <Login />
            },
            {
                path: '/cgv-damay',
                element: <ConditionVent />
            },
            {
                path: '/user',
                element: <PrivateRoute>
                    <Index />
                </PrivateRoute>,
                children: [
                    {
                        index: true,
                        element: < DataPersonnelles />
                    },
                    {
                        path: '/user/commandes',
                        element: <Commandes />
                    },
                    {
                        path: '/user/addresses',
                        element: <Adresses />
                    },
                ]
            }
        ]
    },


    {
        path: '/admin',
        element: <AdminPrivateRoute> <AdminLayout /> </AdminPrivateRoute>,
        children: [
            {
                index: true,
                element: < Dashboard />
            },

            {
                path: '/admin/Gestion-des-commandes',
                element: <Gestion_commandes />
            },

            {
                path: '/admin/Gestion-Produits',
                element: <GetsionProduits />
            },

            {
                path: '/admin/Gestion-Admins',
                element: <GestionAdmins />
            },

            {
                path: '/admin/subscribers',
                element: <Subscribers />
            },

            {
                path: '/admin/Gestion-zone-de-Livraison',
                element: <ManageZone />
            },

            {
                path: '/admin/Gestion-coupon',
                element: <ManageCoupon />
            }
        ]
    },

    {
        path: '/admin-login',
        element: <LoginAdmin />
    },

    {
        path: '/PaymentFailure',
        element: <PaymentFailure />
    },

    {
        path: '/PaymentSuccess',
        element: <PaymentSuccess />
    },

    {
        path: '*',
        element: <Error />
    },
])