/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import './index.css';
import Slide from '../components/slide/Slide';
import Carousel from '../components/carousel/Carousel';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButton, CircularProgress } from '@mui/material';
import Slider from "react-slick";


export default function Home() {
  const remiseProducts = {
    "000.000.022": { oldPrice: 650 },
    "000.000.019": { oldPrice: 1900 },
    // "000.000.771": { oldPrice: 600 }, 
    // "000.000.016": { oldPrice: 750},
    // "000.000.024": { oldPrice: 900},
    // "000.000.769": { oldPrice: 1000 },
    "000.000.025": { oldPrice: 1100 },
    // "000.000.017": { oldPrice: 1100 },
  };

  const outOfStock = ['000.000.016','000.000.017','000.000.024','000.000.769','000.000.771','000.000.022','000.000.025','000.000.769'];

  const [width, setwidt] = useState(window.innerWidth)
  const logosMarque = [
    '/03-PNG/logos_marque/02 - Logo PROAGIT V2.png',
    '/03-PNG/logos_marque/photo_2024-07-05_13-11-09.jpg',
    '/03-PNG/logos_marque/photo_2024-07-05_13-18-4(2).png',
    '/03-PNG/logos_marque/photo_2024-07-05_13-11-09 (2).png',
    '/03-PNG/logos_marque/photo_2024-07-05_13-18-41 (3).jpg',
    '/03-PNG/logos_marque/photo_2024-07-05_13-18-41 (5).png',
    '/03-PNG/logos_marque/photo_2024-07-05_13-18-41 (4).jpg',
  ]
  const settings_carousel_marque = {
    dots: true,
    infinite: true,
    slidesToShow: width < 576 ? 2 : 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1000,
  };
  useEffect(() => {
    const handleResize = () => {
      setwidt(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const robots = useSelector(state => state.Products.robots);
  const piecesRechange = useSelector(state => state.Products.piecesRechange);
  const [chosenType, setchosenType] = useState('robot');
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    setIsPlaying(true);
  };


  const flattenedProducts = robots.flatMap(product =>
    product.variants.map(variant => ({
      ...product,
      variant
    }))
  );




  return (
    <div className='Page_Home'>
      <Slide />

      <div className='section_icons_laivraison '>
        <div className='content_first_section d-none d-md-block container-fluid container-xxl '>
          <div className='row justify-content-between p-3'>
            <div className='col-12 col-md-2 text-center'>
              <img  src={'./GERMAN-ICON-1.gif'} alt="gif" />
              <h4>Deutsch Quality</h4>
              <p>Qualité allemande</p>
            </div>
            <div className='col-12 col-md-2 text-center'>
              <img src={'./Money.gif'} alt="gif" />
              <h4>Livraison</h4>
              <p>Livraison partout au Maroc</p>
            </div>
            <div className='col-12 col-md-2 text-center'>
              <img src={'./Truck1.gif'} />
              <h4>Garantie</h4>
              <p>6 mois de garantie</p>
            </div>
            <div className='col-12 col-md-2 text-center'>
              <img  src={'./Return.gif'} />
              <h4>Paiement</h4>
              <p>Paiement à la livraison</p>
            </div>
            <div className='col-12 col-md-2 text-center'>
              <img  src={'./Call.gif'} />
              <h4>6 jours / 7 de 8:30h à 17h</h4>
              <p>Aide et Assistance</p>
            </div>
          </div>
        </div>
      </div>

      <div className='section2background'>
        <div className='second_section_decouvrez text-center container-fluid mt-5'>
          <div className='row p-4 p-md-2'>
            <div className='col-12 col-lg-6'>
              <div data-aos="fade-right">
                <img  style={{ width: '100%', aspectRatio: 1, maxWidth: '600px' }} src={'/images/3000-SS-1024x1024.png'} />
              </div>
            </div>
            <div className='col-12 col-lg-6'>
              <h2 style={{ width:"100%",maxWidth:'520px' }} data-aos="fade-down" className=''>L’indispensable pour chaque cuisine Marocaine</h2>
              <div data-aos="fade-left" className='textcontent'>
                Découvrez le broyeur à épices et à café électrique DAMAY, conçu pour améliorer vos plats avec une saveur exceptionnelle. Doté d’une technologie allemande, ce moulin électrique permet un broyage rapide des grains, herbes et fruits secs. Profitez des arômes frais de la cannelle, du poivre noir et du beurre d’amande, tout en contrôlant votre santé en évitant les additifs. Idéal pour les régimes spéciaux comme le sans gluten, ce broyeur garantit des repas sains et savoureux. Découvrez la commodité du robot broyeur DAMAY pour des saveurs audacieuses. Investissez dès aujourd’hui dans l’ultime broyeur à épices de DAMAY.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='third_section_nosRobot container-xxl'>
        <div className='my-5'>
          <h2 className='section_title my-3'>Découvrez nos produit</h2>
          <div className='produitType d-flex justify-content-center align-items-center'>
            <button onClick={() => setchosenType('robot')} className={`buttontypeproduct mx-2 ${chosenType === 'robot' ? 'chosenType' : ''}`}>Robot électrique Damay</button>
            <button onClick={() => setchosenType('pieces')} className={`buttontypeproduct mx-2 ${chosenType === 'pieces' ? 'chosenType' : ''}`}>Pieces de rechange</button>
          </div>
          <div className='content_nosRobot d-flex justify-content-around my-5'>
          <div className='row'>
            {robots.length < 1 && piecesRechange.length < 1 ? (
              <div style={{ overflow: 'hidden' }} className='p-3'>
                <CircularProgress />
              </div>
            ) : chosenType === 'robot' ? (
              flattenedProducts.map((item, index) => {
                const isOutOfStock = outOfStock.includes(item.variant.Ref);
                const productLink = `/Product/${item.slug}/${item.variant.security === 1 ? 'securite' : ''}`;
          
                return (
                  <div key={index} className='text-center col-6 col-md-3 position-relative'>
                    {isOutOfStock ? (
                      // If out of stock, disable product and add "OUT OF STOCK" label
                      <div className="disabled-product">
                        <div className="p-2 mx-4 d-flex justify-content-center">
                          <IconButton className='p-3 card-image justify-content-center d-flex flex-column' disabled>
                            {remiseProducts[item.variant.Ref] && (
                              <div className='remisecard'>
                                <p className='mb-0'>-20%</p>
                              </div>
                            )}
                            <div style={{ position: 'relative' }}>
                              <img 
                                style={{ width: '250px', height: '250px', borderRadius: '50%', filter: 'grayscale(100%)' }} 
                                src={item.variant.images.length > 0 ? item.variant.images[0].image_url : undefined} 
                                alt="Product"
                              />
                              <div className="out-of-stock-label">OUT OF STOCK</div>
                            </div>
          
                            <div className='secure-label'>
                              {item.variant.security === 1 ? 'Avec sécurité' : 'Sans sécurité'}
                            </div>
                          </IconButton>
                        </div>
          
                        <div style={{ width: '90%' }} className='mx-auto'>
                          <div className='price_oldprice'>
                            {remiseProducts[item.variant.Ref] && (
                              <span className="old-price">{remiseProducts[item.variant.Ref].oldPrice.toFixed(2)} dh</span>
                            )}
                            <span className="new-price">{item.variant.price}dh</span>
                          </div>
                          <p style={{ fontSize: '18px', fontWeight: '600' }}>{item.name}</p>
                        </div>
                      </div>
                    ) : (
                      // If in stock, wrap with Link
                      <Link to={productLink} className='text-center col-6 col-md-3 position-relative'>
                        <div className='p-2 mx-4 d-flex justify-content-center'>
                          <IconButton className='p-3 card-image justify-content-center d-flex flex-column'>
                            {remiseProducts[item.variant.Ref] && (
                              <div className='remisecard'>
                                <p className='mb-0'>-20%</p>
                              </div>
                            )}
          
                            <img 
                              style={{ width: '250px', height: '250px', borderRadius: '50%' }} 
                              src={item.variant.images.length > 0 ? item.variant.images[0].image_url : undefined} 
                              alt="Product"
                            />
          
                            <div className='secure-label'>
                              {item.variant.security === 1 ? 'Avec sécurité' : 'Sans sécurité'}
                            </div>
                          </IconButton>
                        </div>
          
                        <div style={{ width: '90%' }} className='mx-auto'>
                          <div className='price_oldprice'>
                            {remiseProducts[item.variant.Ref] && (
                              <span className="old-price">{remiseProducts[item.variant.Ref].oldPrice.toFixed(2)} dh</span>
                            )}
                            <span className="new-price">{item.variant.price}dh</span>
                          </div>
                          <p style={{ fontSize: '18px', fontWeight: '600' }}>{item.name}</p>
                        </div>
                      </Link>
                    )}
                  </div>
                );
              })
            ) : (
              piecesRechange?.map((item, index) => (
                <Link key={index} to={`/Product/Pieces-rechange/${item.slug}`} className='text-center col-6 col-md-3 col-lg-4'>
                  <div style={{ borderRadius: '50%' }} className='p-2 mx-4'>
                    <IconButton className='p-3 card-image justify-content-center d-flex flex-column'>
                      <img  
                        style={{ width: '150px', height: '150px', borderRadius: '50%' }} 
                        src={item.types.length > 0 && item.types[0].images.length > 0 ? item.types[0].images[0].image_url : undefined} 
                        alt="Piece"
                      />
                    </IconButton>
                  </div>
                  <div style={{ width: '90%' }} className='mx-auto'>
                    <p style={{ fontSize: '15px', fontWeight: '500' }}>{item.name}</p>
                  </div>
                </Link>
              ))
            )}
          </div>
          
          </div>
        </div>
      </div>

      <div className='section2background'>
        <div className='second_section_decouvrez text-center container-fluid container-xxl mt-5'>
          <div className='row p-4 p-md-2'>
            <div className='col-12 col-lg-6'>
              <h2 style={{ maxWidth:'520px' }} data-aos="fade-down" className=''>{`La différence entre les broyeurs d'épices DAMAY`}</h2>
              <div data-aos="fade-right" className='textcontent'>
                <ul className='p-0' style={{ listStyle: 'inside' }}>
                  <li className='mb-3' style={{ fontSize: '20px', fontWeight: 600 }}>
                    Vous avez toujours galéré à trouver un broyeur puissant et sécurisé, la solution est Damay.
                  </li>

                  <li className='mb-3' style={{ fontSize: '20px', fontWeight: 600 }}>
                    Damay vous propose le broyeur avec ou sans système de sécurité selon votre choix.
                  </li>
                  <li className='mb-3' style={{ fontSize: '20px', fontWeight: 600 }}>
                    <a target="_blank" href='https://www.instagram.com/p/CvW_nCQt_Oj/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=='>
                      Le système de sécurité est équipé d’un interrupteur de verrouillage qui empêche le broyeur de fonctionner si le couvercle n’est pas correctement fermé.
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-12 col-lg-6'>
              <div data-aos="fade-left">
                <div className="video-container" onClick={handlePlayVideo}>
                  {!isPlaying && (
                    <div className="video-cover">
                      <img  style={{ width: '100%' }} src={'/homevd-cover.png'} alt="Cover" />
                    </div>
                  )}
                  {isPlaying && (
                    <iframe
                      width="660"
                      height="415"
                      style={{ maxWidth: '100%' }}
                      src="https://www.youtube.com/embed/_F4eC6C0rJA?autoplay=1"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: '#fff' }} className='Avis_clients mt-5'>
        <div className='container-fluid container-xxl'>
          <h2 className='section_title my-3'>Avis clients</h2>
          <div className='screenshoots'>
            <Carousel content={'Avis_clients'} />
          </div>
        </div>
      </div>

      <div className='our_marque'>
        <div className='container_fluid container-xl'>
          <div style={{ overflow:'hidden' }} className='marques_carousel my-5 py-3'>
            <div >
              <Slider {...settings_carousel_marque}>
                {logosMarque.map((item, index) => (
                  <div className='' key={index}>
                    <div className="card mx-4" >
                      <div className=''>
                        <img style={{ width: '100%', height: '100%' }} src={item} className="card-img-top" alt="marque" />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
