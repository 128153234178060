import { Link } from "react-router-dom";
import './index.css'

export default function PolitiqueConfidentialite() {
    return (
        <div className='PolitiqueConfidentialite'>
            <div style={{ backgroundColor: '#e8eaed' }} className='AboutUsContent py-5'>
                <div className='container-fluid container-xl'>
                    <div style={{ backgroundColor: '#fff' }} className='AboutUsContent1 p-4'>
                        <h1 className='title text-center py-4 mb-0'>Politique de confidentialité</h1>
                        <hr className='my-5' />
                        <div className="explain">
                            <p>
                                La présente politique de confidentialité a pour objectif d’informer les utilisateurs du site <Link to={'/'} >https://damay-co.com/</Link> sur la collecte, l’utilisation et la gestion de leurs données personnelles. Elle couvre notamment les éléments suivants :
                            </p>
                            <ul >
                                <li>Les données personnelles que nous collectons</li>
                                <li>L’utilisation qui est faite de ces données</li>
                                <li>Les personnes ayant accès aux données recueillies</li>
                                <li>Les droits des utilisateurs en matière de protection de leurs données personnelles</li>
                                <li>La politique de gestion des cookies sur le site</li>
                                <li>Cette politique de confidentialité s’applique en complément des conditions générales d’utilisation de notre site.</li>
                            </ul>
                            <p>Cette politique de confidentialité s’applique en complément des conditions générales d’utilisation de notre site. </p>
                        </div>
                        <div className='Loisapplicables'>
                            <h2 style={{ fontSize: '1.5rem' }} className='title p-0 mb-0'>Lois applicables </h2>
                            <div className="content">
                                <p>{`Les données personnelles que nous collectons incluent, mais ne sont pas limitées à, les informations suivantes :   `}</p>
                            </div>
                            <ul>
                                <li>Nom, prénom, adresse, numéro de téléphone </li>
                                <li>Informations relatives aux commandes effectuées sur notre site </li>
                                <li>Adresses IP collectées lors de la navigation sur notre site</li>
                                <li> Ces données sont collectées dans le but de fournir un service de qualité, notamment pour traiter les commandes, répondre aux demandes des utilisateurs, et améliorer l’expérience utilisateur sur <Link to={'/'}> https://damay-co.com/ </Link>.</li>
                            </ul>
                        </div>

                        <div className='donneespersonnelles'>
                            <h2 style={{ fontSize: '1.5rem' }} className='title p-0 mb-0'>Collecte et utilisation des données personnelles </h2>
                            <div className="content">
                                <p>{`Les données personnelles que nous collectons incluent, mais ne sont pas limitées à, les informations suivantes :`}</p>
                            </div>
                            <ul>
                                <li>Nom, prénom, adresse électronique, numéro de téléphone</li>
                                <li>Informations relatives aux commandes effectuées sur notre site</li>
                                <li>Adresses IP collectées lors de la navigation sur notre site</li>
                                <li>Ces données sont collectées dans le but de fournir un service de qualité, notamment pour traiter les commandes, répondre aux demandes des utilisateurs, et améliorer l’expérience utilisateur sur <Link to="/">www.damay-co.com</Link></li>
                            </ul>
                        </div>

                        <div className='Accesauxdonnees'>
                            <h2 style={{ fontSize: '1.5rem' }} className='title p-0 mb-0'>Accès aux données </h2>
                            <div className="content">
                                <p>{`Seules les personnes habilitées au sein de notre société ou nos prestataires de services (dans la mesure où cela est nécessaire pour exécuter leurs missions) peuvent avoir accès aux données personnelles des utilisateurs. Aucune donnée ne sera partagée avec des tiers sans le consentement explicite des utilisateurs, sauf dans les cas prévus par la loi marocaine. `}</p>
                            </div>
                        </div>

                        <div className='Droitsdesutilisateurs'>
                            <h2 style={{ fontSize: '1.5rem' }} className='title p-0 mb-0'>Droits des utilisateurs</h2>
                            <div className="content">
                                <p>{`Conformément à la loi n° 09-08, les utilisateurs disposent des droits suivants concernant leurs données personnelles :`}</p>
                            </div>
                            <ul>
                                <li>Droit d’accès : Les utilisateurs peuvent demander à consulter les données personnelles que nous avons recueillies à leur sujet.</li>
                                <li>Droit de rectification : Les utilisateurs peuvent demander à corriger les données inexactes ou incomplètes.</li>
                                <li>Droit à l’effacement : Les utilisateurs peuvent demander la suppression de leurs données, sous réserve des obligations légales de conservation.</li>
                                <li>Droit à la limitation du traitement : Les utilisateurs peuvent demander une limitation du traitement de leurs données personnelles dans certains cas.</li>
                                <li>Droit d’opposition : Les utilisateurs peuvent s’opposer, pour des raisons légitimes, au traitement de leurs données personnelles.</li>
                            </ul>
                            <p> Pour exercer ces droits, les utilisateurs peuvent contacter DAMAY via les coordonnées fournies sur le site <Link to={'/'}>https://damay-co.com/</Link></p>
                            <p>Toute demande sera traitée dans les meilleurs délais, conformément à la loi en
                                vigueur.</p>
                        </div>

                        <div className='Politiquedecookies'>
                            <h2 style={{ fontSize: '1.5rem' }} className='title p-0 mb-0'>Politique de cookies</h2>
                            <div className="content">
                                <p>{`Notre site utilise des cookies afin d’améliorer l’expérience utilisateur, d’analyser le trafic du site et de personnaliser les contenus et publicités. Les utilisateurs peuvent accepter ou refuser les cookies en configurant leur navigateur. Le refus des cookies peut affecter certaines fonctionnalités du site.`}</p>
                            </div>
                        </div>

                        <div className='Sécuritedesdonnees'>
                            <h2 style={{ fontSize: '1.5rem' }} className='title p-0 mb-0'>Sécurité des données</h2>
                            <div className="content">
                                <p>{`DAMAY s’engage à mettre en place les mesures techniques et organisationnelles nécessaires pour assurer la sécurité des données personnelles contre toute perte, accès non autorisé, divulgation ou destruction. `}</p>
                            </div>
                        </div>

                        <div className='Modificationdelapolitique'>
                            <h2 style={{ fontSize: '1.5rem' }} className='title p-0 mb-0'>Modification de la politique de confidentialité</h2>
                            <div className="content">
                                <p>{`Cette politique de confidentialité peut être mise à jour à tout moment pour refléter les changements dans nos pratiques ou en cas de modification légale. Nous invitons les utilisateurs à consulter cette page régulièrement pour se tenir informés.`}</p>
                                <p>{`Si vous avez des questions concernant cette politique de confidentialité, n’hésitez pas à nous contacter à l'adresse indiquée sur le site.`}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
